<template>
    <div class="d-flex flex-column flex-root vh100">
			<div class="login login-5 login-signin-on d-flex flex-row-fluid" id="kt_login">
				<div class="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid"  v-bind:style="{ 'background-image': 'url(' + bg_image + ')' }">
					<div class="login-form text-center text-white p-7 position-relative overflow-hidden">
						<div class="d-flex flex-center mb-15">
							<a href="#">
								<img src="@/assets/images/logo-big.png" class="max-h-75px" alt="" />
							</a>
						</div>
						<div class="login-signin">
							<div class="mb-20">
								<h3 class="font-weight-normal">Forgot Password</h3>
								<p class="">Enter your email to reset your password</p>
							</div>
        					<Message  v-if="status == 1" severity="error">Email address was not found, Please try again...</Message>
        					<Message  v-if="status == 2" severity="success">Please check your email inbox for instruction on how to change your password...</Message>
							<form class="form" @submit.prevent="reset"  method="post" id="kt_login_signin_form">
								<div class="form-group">
									<input class="form-control h-auto rounded-pill border-0 py-4 px-8" type="text" placeholder="Email" name="email" v-model="email" autocomplete="off" />
								</div>
								<div class="form-group text-center mt-10">
									<button  :disabled="forgot_password_loading" type="submit" id="kt_login_signin_submit" class="btn btn-pill btn-primary opacity-90 px-15 py-3">
										<span v-if="!forgot_password_loading">Reset Password</span>
										<span v-else> <i class="fas fa-spinner fa-spinner"></i> Resetting</span>
									</button>
								</div>
								<a href="/" class="mt-5"> Login</a>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
</template>
<script>
import { ref, onMounted, reactive, inject, watch } from 'vue'
import  UsersForgotPassword  from '@/composables/users/UsersForgotPassword'

import Message from 'primevue/message';
export default {
	components: { Message },
    setup() {
		const { appRouter, router, route, constants, http, store } = inject('App')
		
		const bg_image = require('@/assets/themes/assets/media/bg/bg-2.jpg')
		const email = ref('')
        const { forgot_password_loading, status, forgotPassword } = UsersForgotPassword(http, constants)

		const vm = reactive({
			loading: false
		})
	    function reset() {
			forgotPassword(email.value)
		}
		function forgot(n) {
            appRouter.routeTo(route, router, 'Forgot Password')
        }
		watch(forgot_password_loading, (n, o) => {
            if (forgot_password_loading.value == false) {

            }
        });
		onMounted(() => {
        })
		return {
             bg_image,
			 email,
			 vm, 
			 forgot_password_loading,
			 status,
			 reset,
			 forgot
		}
	},
	mounted() {
		
	}
}
</script>
<style scoped src="@/assets/themes/assets/css/pages/login/classic/login-5.css"></style> 