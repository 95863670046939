import { ref } from 'vue'
export default function UsersForgotPassword(http, constant) {
    let forgot_password_loading = ref(false)
    let status = ref(0)
    
    async function forgotPassword (email) {
        forgot_password_loading.value = true
        status.value = 0
        try {
            const f = new URLSearchParams()
            f.append('email', email)
            const response = await http.post(constant.USERS_FORGOT_PASSWORD, f)
            
            if (response.data.status == 0)
                status.value = 1
            else status.value = 2
            
            forgot_password_loading.value = false
        } catch (err) {
            forgot_password_loading.value = false
        }
    }
    return {
        forgot_password_loading, status, forgotPassword
    }
}